import type { NextPage } from "next";
import React from "react";
import HomeContent from "@/components/HomeContent";
import BaseHeader from "@/components/BaseHeader";
import { getContentByPath } from "@/api/contentApis";
import { ContentPage } from "@/types/PropTypes";

export async function getServerSideProps() {
  const data = await getContentByPath("home");

  return {
    props: {
      ...data,
    },
  };
}

const Home: NextPage<ContentPage> = ({ metaTitle, metaDescription, metaKeywords, content }) => {
  return (
    <>
      <BaseHeader title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <HomeContent content={content} />
    </>
  );
};

export default Home;
